/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../layout/layout'
import { Hero, Section, Gallery } from '../components'

class ServiceTemplate extends React.Component {
  render() {
    const service = get(this.props, 'data.contentfulService')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const gallery = get(this.props, 'data.contentfulService.gallery')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={`${service.title} | ${siteTitle}`} />
          <Hero
            title={service.title}
            subtitle={service.subtitle}
            button='Contact Us'
            buttonUrl='/contact'
            size={[4, 6]}
            minHeight={[0, '40vh']}
          />

          <Section variant='transparent'>
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: ['column', 'row'],
              }}
            >
              <Img
                alt={service.title}
                fixed={service.image.fixed}
                sx={{
                  borderRadius: '8px',
                  mr: [0, 5],
                  mb: [3, 0],
                  minWidth: ['100%', '400px'],
                  minHeight: ['auto', '400px'],
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: service.description.childMarkdownRemark.html,
                }}
                sx={{
                  p: 0,
                  m: 0,
                  h1: {
                    fontSize: [5, 6],
                    m: 0,
                    p: 0,
                  },
                  '> p': {
                    color: 'text',
                    fontSize: [2, 3],
                    lineHeight: [1.5, 2],
                  },
                }}
              />
            </div>
          </Section>

          {gallery && (
            <Gallery
              title={gallery.title}
              subtitle={`Our recent work in ${service.title}`}
              service={gallery}
              button
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ServiceBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulService(slug: { eq: $slug }) {
      title
      subtitle
      image {
        fixed {
          ...GatsbyContentfulFixed
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        assets {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
